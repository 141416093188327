<template>
  <div class="md-layout">
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
    >
      <md-card>
        <md-card-header data-background-color="blue" class="card-header">
          <div class="card-header-info">
            <h4 class="title">Редактировать пользователя</h4>
          </div>
        </md-card-header>
        <ValidationObserver ref="editUserForm" :slim="true">
          <md-card-content>
            <ValidationProvider
              v-slot="{ errors, reset }"
              mode="passive"
              :slim="true"
              rules="required"
            >
              <md-field :class="{ error: errors.length }">
                <ValidationError :errors="errors" />
                <label>Никнейм</label>
                <md-input v-model="nickname" @focus="reset" />
              </md-field>
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors, reset }"
              mode="passive"
              :slim="true"
              rules="required"
            >
              <md-field :class="{ error: errors.length }">
                <ValidationError :errors="errors" />
                <label>ФИО</label>
                <md-input v-model="name" @focus="reset" />
              </md-field>
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors, reset }"
              mode="passive"
              :slim="true"
              rules="required"
            >
              <md-field :class="{ error: errors.length }">
                <ValidationError :errors="errors" />
                <label>E-mail</label>
                <md-input v-model="email" @focus="reset" />
              </md-field>
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors, reset }"
              mode="passive"
              :slim="true"
              rules="required"
            >
              <md-field :class="{ error: errors.length }">
                <ValidationError :errors="errors" />
                <label>Телефон</label>
                <md-input
                  v-model="phone"
                  v-mask="'+# (###) ###-##-##'"
                  md-counter="false"
                  maxlength="18"
                  @focus="reset"
                />
              </md-field>
            </ValidationProvider>

            <div class="buttons-wrap">
              <md-button class="md-raised md-success" @click="rewriteUser">
                Сохранить
              </md-button>
            </div>
          </md-card-content>
        </ValidationObserver>
      </md-card>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  data() {
    return {
      nickname: "",
      name: "",
      phone: "",
      email: "",
    };
  },

  computed: {
    ...mapState({
      user: (state) => state.users.user,
    }),
  },

  async mounted() {
    this.$store.commit("SET_SHOW_LOADER", true);
    let success = await this.getUser({ user_id: +this.$route.params.id });
    if (success) {
      this.nickname = this.user.nickname;
      this.name = this.user.name;
      this.phone = this.user.phone;
      this.email = this.user.email;
    }
    this.$store.commit("SET_SHOW_LOADER", false);
  },

  methods: {
    ...mapActions("users", ["getUser", "updateUser"]),

    async rewriteUser() {
      const isValid = await this.$refs.editUserForm.validate();
      if (!isValid) {
        return;
      }

      let user = {
        nickname: this.nickname,
        name: this.name,
        phone: this.phone.replaceAll(/[-() ]/g, ""),
        email: this.email,
      };

      this.$store.commit("SET_SHOW_LOADER", true);
      let success = await this.updateUser({ id: this.user.id, user });
      if (success) {
        this.$router.push(`/users/${this.user.id}`);
      }
      this.$store.commit("SET_SHOW_LOADER", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.buttons-wrap {
  display: flex;
  justify-content: space-between;
}
</style>
